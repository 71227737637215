(function (branch, options) {
  var sensorProject = 'default';
  const Url = require('url-parse');
  const urlObj = new Url(window.location.href);
  // 生产环境或master分支都向CMA发送埋点数据
  if (urlObj?.hostname === 'm.mcd.cn' || branch === 'master') {
    sensorProject = 'CMA';
  }
  // if (branch === 'master') sensorProject = 'default';

  (function (para) {
    var p = para.sdk_url, n = para.name, w = window, d = document, s = 'script', x = null, y = null;
    if (typeof (w['sensorsDataAnalytic201505']) !== 'undefined') {
      return false;
    }
    w['sensorsDataAnalytic201505'] = n;
    w[n] = w[n] || function (a) { return function () { (w[n]._q = w[n]._q || []).push([a, arguments]); } };
    var ifs = ['track', 'quick', 'register', 'registerPage', 'registerOnce', 'trackSignup', 'trackAbtest', 'setProfile', 'setOnceProfile', 'appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify', 'login', 'logout', 'trackLink', 'clearAllRegister', 'getAppStatus'];
    for (var i = 0; i < ifs.length; i++) {
      w[n][ifs[i]] = w[n].call(null, ifs[i]);
    }
    if (!w[n]._t) {
      x = d.createElement(s);
      y = d.getElementsByTagName(s)[0];
      x.async = 1;
      x.src = p;
      x.setAttribute('charset', 'UTF-8');
      w[n].para = para;
      y.parentNode.insertBefore(x, y);
    }
  })({
    show_log: true,
    sdk_url: 'https://cdn.mcd.cn/cms/assets/js/sensorsdata.min.js',
    heatmap_url: 'https://cdn.mcd.cn/cms/assets/js/heatmap.min.js',
    name: 'sensors',
    server_url: `https://tracking.mcdonalds.com.cn/sa?project=${sensorProject}`,
    app_js_bridge: true,
    heatmap: {
      scroll_notice_map: 'not_collect',
      clickmap: 'default'
    }
  });
  window.sensors.registerPage(options);
  window.sensors.quick('autoTrack');
})(process.env.REACT_APP_BRANCH, {
  platform_type: "new app"
});