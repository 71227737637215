import React from "react";
// import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '@/components/Home';
import Download from '@/components/Download';
import Transfer from '@/components/Transfer';
import QrStore from './qr/Store';
import QrSignIn from './qr/SignIn';
import Login from './login/Index';
import QrTest from './qr/Test';
import "@/assets/styles/App.less";
import QrQd from "./qr/Store";
import storeToolAuto from "./qr/StoreToolAuto";
import StoreTool from "./qr/StoreTool";
import Invitation from "./Invitation";
import InviteDownload from "./InviteDownload";
import RewardDetail from "./RewardDetail";
import AcceptInviteDownload from "./AcceptInviteDownload";
import RedpacketActivityList from "./RedpacketActivityList";
import Redpacket from "./Redpacket";
import GiftCard from "./GiftCard";
import PageNotFound from "@/components/PageNotFound";
import * as common from "@/common/common";
import Lottery from "./Lottery";
import VideoDemo from "./VideoDemo";
import AKS from "./aks";
import TakeOffer from "./TakeOffer";
import Individualization from "./Individualization";

function App() {
  const renderDownload = () => {
    // const sensors: any = (window as any).sensors;
    // sensors.quick("autoTrackSinglePage");
    return <Download banners={[common.CDN_URLS.downloadBg] as any} />;
  };

  const renderTransfer = () => {
    // const sensors: any = (window as any).sensors;
    // sensors.quick("autoTrackSinglePage");
    return (
      <Transfer banners={[common.CDN_URLS.downloadBg] as any} />
    )
  }

  const setDocumentTitle = (title = '') => {
    let tempTitle = title === '' ? '麦当劳点餐' : title;
    document.title = tempTitle;
  };

  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          {/* <Provider store={store}> */}
          <Route path='/login' exact={true} render={() => <>{setDocumentTitle('麦当劳')}<Login /></>} />
          <Route path='/qr/s/:storeId?' component={QrStore} />
          <Route path='/qr/test' component={QrTest} />
          <Route path='/storeToolAuto' component={storeToolAuto} />
          <Route path='/qr/qd/:storeId?' component={QrQd} />
          <Route path='/s' component={QrStore} />
          <Route path='/q' component={QrStore} />
          <Route path='/wv' component={QrSignIn} />
          <Route path='/tool/qrcode/store' component={StoreTool} />
          <Route path='/app' exact={true} render={renderDownload} />
          {/* 新增 download 路由地址 */}
          <Route path='/download' exact={true} render={renderDownload} />
          <Route path='/transfer' exact={true} render={renderTransfer} />
          <Route path="/invitation" exact={true} render={() => <>{setDocumentTitle('2021江苏市场新年晚宴')}<Invitation /></>} />
          <Route path="/inviteDownload" exact={true} render={() => <><InviteDownload /></>} />
          <Route path="/rewardDetail" exact={true} render={() => <>{setDocumentTitle('我的奖励')}<RewardDetail /></>} />
          <Route path="/acceptInviteDownload" exact={true} render={() => <>{setDocumentTitle('麦当劳全新APP')}<AcceptInviteDownload /></>} />  
          <Route path="/cm/wecom/redpacket" exact={true} component={RedpacketActivityList} />
          <Route path="/cm/wecom/redpacketActivity/:activityCode/:styleType/:activityName/:beginTime/:endTime/:storeCode?" component={Redpacket} />
          <Route path="/cm/wecom/lottery" exact={true} component={Lottery} />
          <Route path="/giftcard" component={GiftCard} />
          <Route path="/" exact={true} component={Home} />
          <Route path="/videoDemo" exact={true} component={VideoDemo} />
          <Route path="/aks" exact={true} component={AKS} />
          <Route
            path="/takeOffer"
            exact={true}
            render={() => (
              <>
                {/* {setDocumentTitle("麦当劳入职通知")} */}
                <TakeOffer />
              </>
            )}
          />
          <Route path="/individualizationPage" exact={true} component={Individualization} />
          <Route component={PageNotFound} />
        </Switch>
        {/* </Provider> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
